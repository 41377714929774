import { connection } from '../..';

export function updateRemote(store, entity, data) {
  connection.update(entity, data);
}

export function createRemote(store, entity, data) {
  connection.create(entity, data);
}

export function removeRemote(store, entity, data) {
  connection.remove(entity, data);
}
