import { ReactComponent as GameIcon } from './svg/game.svg';
import { ReactComponent as LogOutIcon } from './svg/logout.svg';
import { ReactComponent as MarketIcon } from './svg/market.svg';
import { ReactComponent as MeetIcon } from './svg/meet.svg';
import { ReactComponent as UnionIcon } from './svg/union.svg';
import { ReactComponent as PersonIcon } from './svg/person.svg';
import { ReactComponent as MoneyIcon } from './svg/money.svg';
import { ReactComponent as Logo } from './svg/logo.svg';
import { ReactComponent as Novartis } from './svg/Novartis.svg';
import { ReactComponent as UserInfo } from './svg/UserInfo.svg';
import { ReactComponent as Info } from './svg/info.svg';
import { ReactComponent as Star } from './svg/star.svg';
import { ReactComponent as AddIcon } from './svg/add.svg';
import { ReactComponent as Upload } from './svg/upload.svg';

export const ICONS = {
  GameIcon,
  LogOutIcon,
  MarketIcon,
  MeetIcon,
  UnionIcon,
  PersonIcon,
  MoneyIcon,
  Logo,
  Novartis,
  UserInfo,
  Star,
  AddIcon,
  Upload,
  Info,
};

export default ICONS;
