import { getCookie } from '../../../utils/cookie';

const STRUCT = {
  PIN: 'pin',
  ROLE: 'role',
  PIN_SENDING: 'pin_sending',
  CONNECTED: 'connected',
  AUTHORIZED: 'authorized',
  USER_ID: 'user_id',
};

export default STRUCT;

export const AUTHORIZED = 'novartis_authorized';

export const initialState = () => ({
  [STRUCT.PIN]: '',
  [STRUCT.ROLE]: 0,
  [STRUCT.PIN_SENDING]: false,
  [STRUCT.CONNECTED]: false,
  [STRUCT.AUTHORIZED]: getCookie(AUTHORIZED) === 'true',
  [STRUCT.USER_ID]: null,
});
