import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { branch } from 'baobab-react/higher-order';

import Table, { TYPES as TABLE_TYPES } from '../../../../components/table';

import styles from '../index.module.scss';
import { sixIbsParamSelector } from '../../../../store/struct/selectors';
import SIXIBS_PARAM_STRUCT from '../../../../store/struct/entities/sixIbsParam';

function getOptions(max) {
  return Array.from(new Array(max + 1), (val, i) => ({
    id: String(i),
    title: i,
  }));
}

const Estimates = ({ onChange, sixIbsParam }) => {
  const tableData = sixIbsParam.map(param => ({
    id: param[SIXIBS_PARAM_STRUCT.ID],
    name: param[SIXIBS_PARAM_STRUCT.TITLE],
  }));

  const [state, setState] = useState(
    tableData.reduce((result, param) => {
      result[param.id] = 0;

      return result;
    }, {}),
  );

  const tableConfig = useMemo(
    () => [
      {
        type: TABLE_TYPES.TEXT,
        getValue: data => data.name,
      },
      {
        type: TABLE_TYPES.CONDITIONAL,
        getComponentIndex: data => (data.id === 6 ? 1 : 0),
        components: [
          {
            type: TABLE_TYPES.SELECT,
            onChange: (data, value) => setState({ ...state, [data.id]: value }),
            getValue: () => '',
            options: getOptions(6),
            min: true,
          },
          {
            type: TABLE_TYPES.SELECT,
            onChange: (data, value) => setState({ ...state, [data.id]: value }),
            getValue: () => '',
            options: getOptions(20),
            min: true,
          },
        ],
      },
    ],
    [state],
  );

  useEffect(() => {
    onChange(state);
  }, [state, onChange]);

  return (
    <Table className={styles.estimatesTable} headers={['Оценка', 'Значение']} config={tableConfig} data={tableData} />
  );
};

Estimates.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default branch(
  {
    sixIbsParam: sixIbsParamSelector(),
  },
  Estimates,
);
