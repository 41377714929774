import React from 'react';
import PropTypes from 'prop-types';
import { branch } from 'baobab-react/higher-order';

import Dialog from '../../components/dialog';
import Table, { TYPES as TABLE_TYPES } from '../../components/table';
import { INVEST_TABLE_HEADERS } from '../agent/meetings';
import EVENT_STRUCT from '../../store/struct/entities/event';
import { eventsSelector, investsSelector } from '../../store/struct/selectors';
import INVEST_STRUCT from '../../store/struct/entities/invest';
import find from 'lodash.find';
import includes from 'lodash.includes';

function getInvestStatus(eventData, invests, investIds) {
  const clientInvests = invests.filter(invest => includes(investIds, invest[INVEST_STRUCT.ID]));
  const invest = find(clientInvests, invest => invest[INVEST_STRUCT.EVENT_ID] === eventData[EVENT_STRUCT.ID]);

  if (!invest) {
    return 0;
  }

  const investIsApplicable = !invest[INVEST_STRUCT.FINE];

  return investIsApplicable ? 1 : 2;
}

function InvestDialog({ onCancel, investIds, events, invests }) {
  const tableInvestConfig = [
    {
      type: TABLE_TYPES.TEXT,
      getValue: eventData => eventData[EVENT_STRUCT.TITLE],
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: eventData => eventData[EVENT_STRUCT.COST],
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: eventData => eventData[EVENT_STRUCT.EFFECT],
    },
    {
      type: TABLE_TYPES.CONDITIONAL,
      getComponentIndex: eventData => getInvestStatus(eventData, invests, investIds),
      components: [
        {
          type: TABLE_TYPES.TEXT,
          getValue: () => '',
        },
        {
          type: TABLE_TYPES.TEXT,
          getValue: () => 'Применено',
        },
        {
          type: TABLE_TYPES.TEXT,
          getValue: () => 'Отказ',
        },
      ],
    },
  ];

  return (
    <Dialog title='Инвестиции' cancelTitle='Закрыть' onCancel={onCancel}>
      <Table headers={INVEST_TABLE_HEADERS} config={tableInvestConfig} data={events} />
    </Dialog>
  );
}

InvestDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  investIds: PropTypes.array.isRequired,
  events: PropTypes.array.isRequired,
  invests: PropTypes.array.isRequired,
};

export default branch(
  {
    events: eventsSelector(),
    invests: investsSelector(),
  },
  InvestDialog,
);
