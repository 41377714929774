import React, { useCallback } from 'react';
import { branch } from 'baobab-react/higher-order';

import { pinSendingStatusSelector, pinSelector, roleSelector, authStatusSelector } from '../store/struct/app/selectors';
import { sendPin } from '../store/struct/app/actions';
import { ROLES } from '../store/struct/entities/role';
import PinInput from '.././components/pin-input';
import { Loader } from '../components/loader';
import AdminContainer from '../containers/admin';
import AgentContainer from '../containers/agent';
import ClientContainer from '../containers/client';

import scssVars from '../styles/_vars.scss';
import styles from './index.module.scss';
import Tabs from '../containers/tabs';

const containers = {
  [ROLES.ADMIN]: AdminContainer,
  [ROLES.AGENT]: AgentContainer,
  [ROLES.CLIENT]: ClientContainer,
};

const App = props => {
  const { role, dispatch, pinSending, authStatus } = props;
  const pinSubmit = useCallback(
    value => {
      dispatch(sendPin, value);
    },
    [dispatch],
  );
  const CurrentContainer = role ? containers[role] : null;

  return (
    <section className={`${scssVars.lightTheme} ${styles.container}`}>
      {!CurrentContainer && authStatus && (
        <>
          <Tabs header='' tabs={[]} />
          <Loader />
        </>
      )}
      {!CurrentContainer && !authStatus && <PinInput disabled={pinSending} onSubmit={pinSubmit} />}
      {CurrentContainer && <CurrentContainer />}
    </section>
  );
};

export default branch(
  {
    pinSending: pinSendingStatusSelector(),
    pin: pinSelector(),
    role: roleSelector(),
    authStatus: authStatusSelector(),
    entities: 'entities',
  },
  App,
);
