import React from 'react';
import PropTypes from 'prop-types';
import { branch } from 'baobab-react/higher-order';

import Dialog from '../../components/dialog';
import { eventsSelector, clientsSelector } from '../../store/struct/selectors';
import Results from './meetings/results';
import REPORT_STRUCT from '../../store/struct/entities/report';
import { getById } from '../../utils';
import { FIELD_STRUCT, FIELDS } from './meetings/results/forms/const';
import styles from './index.module.scss';

function ResultDialog({ events, onCancel, selectedReport, clients }) {
  const mapMeetingResultsToFormState = report => {
    const client = getById(clients, report[REPORT_STRUCT.CLIENT_ID]);
    const clientFormFields = FIELDS.filter(field => client[field[FIELD_STRUCT.ID]]);

    return clientFormFields.reduce((acc, fieldData) => {
      const fieldValue = report[fieldData[FIELD_STRUCT.CONTRIBUTE_TO]];
      let formValue;

      if (typeof fieldValue === 'boolean') {
        formValue = String(Number(fieldValue));
      } else if (typeof fieldValue === 'number') {
        formValue = String(Math.round(fieldValue));
      }

      acc[fieldData[FIELD_STRUCT.CONTRIBUTE_TO]] = formValue;
      return acc;
    }, {});
  };

  return (
    <Dialog title='Результат встречи' cancelTitle='Закрыть' onCancel={onCancel} className={styles.meetingResultDialog}>
      <Results
        onChange={() => {}}
        events={events}
        eventsIds={selectedReport[REPORT_STRUCT.APPOINTED_EVENTS]}
        client={getById(clients, selectedReport[REPORT_STRUCT.CLIENT_ID])}
        initialState={mapMeetingResultsToFormState(selectedReport)}
        isResultDemonstration={true}
        selectedReport={selectedReport}
      />
    </Dialog>
  );
}

ResultDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  events: PropTypes.array.isRequired,
  clients: PropTypes.array.isRequired,
  selectedReport: PropTypes.object.isRequired,
};

export default branch(
  {
    events: eventsSelector(),
    clients: clientsSelector(),
  },
  ResultDialog,
);
