import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.scss';
import Button from '../button';
const PIN_LENGTH = 5;
const pinMask = /\d{4}[a-zA-Z]/;
const matchCompleteMask = str => pinMask.test(str);
const matchInputMask = str => /^\d{0,4}?$/.test(str) || pinMask.test(str);
const trim = str => (matchInputMask(str) ? str : trim(str.slice(0, -1)));

const PinInput = props => {
  const { disabled, onSubmit } = props;
  const [currentPin, setPin] = useState('');

  const onChange = useCallback(event => {
    setPin(trim(event.target.value));
  }, []);

  const handleSubmit = useCallback(() => {
    onSubmit(currentPin);
  }, [onSubmit, currentPin]);

  const handleKeyDown = useCallback(
    e => {
      if (e.key === 'Enter' && currentPin.length === PIN_LENGTH) {
        onSubmit(currentPin);
      }
    },
    [onSubmit, currentPin],
  );

  return (
    <div className={styles.container} onKeyDown={handleKeyDown}>
      <section className={styles.innerWrapper}>
        <h1>Добро пожаловать!</h1>
        <p>Введите свой PIN-код</p>
        <div className={styles.inputsWrapper}>
          <input
            type='password'
            onChange={onChange}
            maxLength={PIN_LENGTH}
            className={styles.input}
            value={currentPin}
          />
        </div>

        <Button
          className={styles.submit}
          onClick={handleSubmit}
          disabled={disabled || !matchCompleteMask(currentPin)}
        >
          Войти
        </Button>
      </section>
    </div>
  );
};

export default PinInput;

PinInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
PinInput.defaultProps = {
  disabled: false,
};
