import React from 'react';
import PropTypes from 'prop-types';

import { branch } from 'baobab-react/higher-order';
import { meSelector } from '../../../store/struct/selectors';

import styles from './userInfo.module.scss';
import { roleSelector } from '../../../store/struct/app/selectors';
import { ROLES } from '../../../store/struct/entities/role';

const UserIcon = ({ name, role }) => {
  const [firstPerson] = name.split(',');
  const firstName = firstPerson.split(' ')[1] || '';
  let firstLetter = firstName[0];

  if (role === ROLES.ADMIN) {
    firstLetter = 'A';
  }

  return <div className={styles.nameIcon}>{firstLetter}</div>;
};

UserIcon.propTypes = {
  name: PropTypes.string.isRequired,
};

const UserName = ({ name }) => {
  const names = name.split(',');

  return names.map((nameItem, index) => (
    <div key={`user_name_${index}`} className={styles.name}>
      {nameItem}
    </div>
  ));
};

UserName.propTypes = {
  name: PropTypes.string.isRequired,
};

const UserInfo = ({ user, shortMode, role }) => {
  const userData = (Array.isArray(user) ? user[0] : user) || {};
  let { name = '', email = '' } = userData;

  if (role === ROLES.ADMIN) {
    name = 'Администратор';
  }

  return (
    <div className={`${styles.userInfo} ${shortMode ? styles.onlyIcon : ''}`}>
      {shortMode ? (
        <UserIcon name={name} role={role} />
      ) : (
        <>
          <UserName name={name} />
          <div className={styles.email}>{email}</div>
        </>
      )}
    </div>
  );
};

UserInfo.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  shortMode: PropTypes.bool.isRequired,
};

UserInfo.defaultProps = {
  user: {},
};

export default branch(
  {
    user: meSelector(),
    role: roleSelector(),
  },
  UserInfo,
);
