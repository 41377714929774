import { calcWithCoef, FIELD_STRUCT, FIELDS } from './const';
import { TYPES as TABLE_TYPES } from '../../../../../components/table';

export function getTableData(client) {
  return FIELDS.filter(field => client[field.id]);
}

export function getTableConfig({ client, resultCoef, state, onChange, isResultDemonstration }) {
  const config = [
    {
      type: TABLE_TYPES.TEXT,
      getValue: fieldData => fieldData[FIELD_STRUCT.HEADER],
    },
    {
      getType: fieldData => fieldData[FIELD_STRUCT.TYPE] || TABLE_TYPES.INPUT,
      getValue: fieldData => state[fieldData[FIELD_STRUCT.CONTRIBUTE_TO]],
      getProps: fieldData => {
        let disabled = isResultDemonstration;
        return { ...fieldData[FIELD_STRUCT.PROPS], disabled };
      },
      onChange: (fieldData, val) => onChange(fieldData, val),
      getOptions: fieldData => fieldData[FIELD_STRUCT.OPTIONS],
      getPostfix: fieldData => (isResultDemonstration ? 'уп.' : fieldData[FIELD_STRUCT.POSTFIX]),
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: fieldData => {
        const val = calcWithCoef({ fieldData, client, resultCoef, state });

        return !fieldData.thirdColumnRequired ? '' : `${Math.round(val)} уп.`;
      },
    },
  ];
  // meeting result demonstration is only shows calculated value with all coefficients so last column is not needed
  return isResultDemonstration ? config.slice(0, -1) : config;
}
