import PropTypes from 'prop-types';
import defaults from 'lodash.defaults';

export default function setFormFieldInterface(Component, defaultsProps = {}) {
  Component.propTypes = {
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    value: PropTypes.any,
    subtype: PropTypes.string,
    attrs: PropTypes.object,
    options: PropTypes.array,
    readonly: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
  };

  Component.defaultProps = defaults(defaultsProps, {
    onChange: () => null,
    onClick: () => null,
    value: '',
    subtype: '',
    attrs: {},
    options: [],
    readonly: false,
    disabled: false,
    className: '',
  });
}
