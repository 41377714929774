const STRUCT = {
  ID: 'id',
  NAME: 'name',
  TEAM_ID: 'team_id',
  STARTING_BUDGET: 'startingBudget',
  FINAL_BUDGET: 'finalBudget',
  GOAL: 'goal',
  EMAIL: 'email',
};

export default STRUCT;
