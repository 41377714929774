import React from 'react';
import PropTypes from 'prop-types';

import { ICONS } from '../index';
import styles from './../add-icon/addIcon.module.scss';

const Icon = ICONS.Info;

const InfoIcon = ({ onClick, className }) => (
  <div className={`${styles.addIcon} ${className}`} onClick={onClick}>
    <Icon width={22} height={22} />
  </div>
);

InfoIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

InfoIcon.defaultProps = {
  className: '',
};

export default InfoIcon;
