import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import { root } from 'baobab-react/higher-order';

import App from './App';
import store from './store';

import * as serviceWorker from './serviceWorker';
import './styles/index.scss';

const RootedApp = root(store, App);

ReactDOM.render(<RootedApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
