import React, { useState, useMemo } from 'react';
import { branch } from 'baobab-react/higher-order';
import find from 'lodash.find';
import { ICONS } from '../../components/Icons';

import { gamesSelector } from '../../store/struct/selectors';
import GAME_STRUCT from '../../store/struct/entities/game';

import Tabs from '../tabs';
import Games from './games';
import Users from './users';

const tabs = [
  {
    title: 'Игры',
    content: Games,
    icon: ICONS.GameIcon,
  },
  /* {
    title: 'Команды',
    content: Teams,
    icon: ICONS.PersonIcon,
  },*/
  {
    title: 'Пользователи',
    content: Users,
    icon: ICONS.PersonIcon,
  },
];

const AdminContainer = props => {
  let { games } = props;

  games = useMemo(() => games.filter(game => !game[GAME_STRUCT.HIDDEN]), [games]);

  const [currentGameId, setCurrentGameId] = useState(null);

  const gamesOptions = useMemo(() => games.map(game => ({ id: game.id, title: game.name })), [games]);
  const currentGame = useMemo(() => find(games, { id: currentGameId }), [games, currentGameId]);

  return (
    <Tabs
      tabs={tabs}
      {...props}
      games={games}
      gamesOptions={gamesOptions}
      currentGame={currentGame}
      currentGameId={currentGameId}
      setCurrentGameId={setCurrentGameId}
    />
  );
};

export default branch(
  {
    games: gamesSelector(),
  },
  AdminContainer,
);
