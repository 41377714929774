import STRUCT from '..';
import APP_STRUCT from '.';

export const connectionStatusSelector = () => [
  STRUCT.APP,
  APP_STRUCT.CONNECTED,
];
export const authStatusSelector = () => [STRUCT.APP, APP_STRUCT.AUTHORIZED];
export const pinSendingStatusSelector = () => [
  STRUCT.APP,
  APP_STRUCT.PIN_SENDING,
];
export const pinSelector = () => [STRUCT.APP, APP_STRUCT.PIN];
export const roleSelector = () => [STRUCT.APP, APP_STRUCT.ROLE];
export const userIdSelector = () => [STRUCT.APP, APP_STRUCT.USER_ID];
