const STRUCT = {
  CUSTOMER_ID: 'customer_id',
  END: 'end',
  ID: 'id',
  START: 'start',
  STATUS: 'status',
  MEETING_ID: 'meeting_id',

  // these info is only sent to the clients
  REPRESENTATIVE_ID: 'representative_id',
};

export const MEETING_STATUSES = {
  pending: 'pending',
  inProgress: 'in progress',
  done: 'done',
};

export const MEETING_TIME = 10 * 60; // 10 min

export default STRUCT;
