import React from 'react';
import { branch } from 'baobab-react/higher-order';
import { ICONS } from '../../../components/Icons';
import TopPanelIcon from '../../../components/Icons/top-panel-icon/topPanelIcon';
import { agentRatingSelector, agentsSelector, meSelector } from '../../../store/struct/selectors';
import USER_STRUCT from '../../../store/struct/entities/user';
import AGENT_RATING_STRUCT from '../../../store/struct/calculated/agentRating';
import { toDecimalPlaces } from '../../../utils';
import styles from './index.module.scss';

const getRatingText = (rating, count = 0) => {
  if (rating >= 1 && rating <= 3) {
    return 'Топ-3';
  } else if (rating >= 4 && rating <= 7) {
    return 'Место 4-7';
  } else if (rating > 7) {
    return `Место 8+ из ${count}`;
  }
};

const Ratings = props => {
  const { user, agents, agentRating } = props;

  if (!user) {
    return null;
  }
  let ratingNumber, ratingEntry;

  for (let i = 0; i < agentRating.length; i++) {
    if (user[USER_STRUCT.ID] === agentRating[i][AGENT_RATING_STRUCT.AGENT_ID]) {
      ratingNumber = i + 1;
      let j = i - 1;
      while (
        j >= 0 &&
        agentRating[i][AGENT_RATING_STRUCT.GRADE_SUM] === agentRating[j][AGENT_RATING_STRUCT.GRADE_SUM]
      ) {
        ratingNumber = j + 1;
        j--;
      }
      ratingEntry = agentRating[i];
      break;
    }
  }

  return (
    <div className={styles.ratings}>
      <TopPanelIcon icon={<ICONS.MoneyIcon width={12} height={14} />} title={'Стартовый бюджет/Текущий бюджет'}>
        {user && `${user[USER_STRUCT.STARTING_BUDGET]}/${user[USER_STRUCT.FINAL_BUDGET]}`}
      </TopPanelIcon>
      <TopPanelIcon icon={<ICONS.Star width={15} height={15} />} title={'Выполнение плана'}>
        {ratingEntry &&
          user &&
          `${toDecimalPlaces((ratingEntry[AGENT_RATING_STRUCT.GRADE_SUM] * 100) / user[AGENT_RATING_STRUCT.GOAL], 2)}%`}
      </TopPanelIcon>
      <TopPanelIcon icon={<ICONS.MarketIcon width={15} height={15} />} title={'Рейтинг'}>
        {getRatingText(ratingNumber, agents.length)}
      </TopPanelIcon>
    </div>
  );
};

export default branch(
  {
    user: meSelector(),
    agents: agentsSelector(),
    agentRating: agentRatingSelector(),
  },
  Ratings,
);
