/**
 * Return range time
 * @param {string} dataStart
 * @param {string} dataEnd
 * @return {string}
 */
export const getTimeRange = (dataStart, dataEnd) => {
  // For excluding time zone
  const getTime = str => str.substr(11, 5);

  return `${getTime(dataStart)}-${getTime(dataEnd)}`;
};
