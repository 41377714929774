import find from 'lodash.find';

export const getById = (array, id) => {
  if (!array || !array.length) {
    return null;
  }

  return find(array, { id }) || null;
};

export const toDecimalPlaces = (value, decimalPlaces) => {
  const k = Math.pow(10, decimalPlaces);

  return Math.round(value * k) / k;
};
