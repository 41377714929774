import Baobab from 'baobab';
import { initialState } from './struct';
import Connection from './connection';

const store = new Baobab(initialState(), {
  immutable: process.env.NODE_ENV === 'development',
  monkeyBusiness: true,
});

export const connection = new Connection(store);

if (process.env.NODE_ENV === 'development') {
  store.on('update', arg => {
    console.groupCollapsed('Transactions');
    arg.data.transaction.forEach(t => {
      console.groupCollapsed(t.path.join(' > '));
      console.log('type: ', t.type);
      console.log('path: ', t.path);
      console.log('value: ', t.value);
      console.groupEnd();
    });
    console.groupEnd();
  });
}

export default store;
