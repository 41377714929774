import React from 'react';
import PropTypes from 'prop-types';

import { ICONS } from '../index';
import styles from './addIcon.module.scss';

const Icon = ICONS.AddIcon;

const AddIcon = ({ onClick, className }) => (
  <div className={`${styles.addIcon} ${className}`} onClick={onClick}>
    <Icon width={22} height={22} />
  </div>
);

AddIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

AddIcon.defaultProps = {
  className: '',
};

export default AddIcon;
