import { createRemote, removeRemote, updateRemote } from '../atomic';
import ENTITIES from '..';

export function createMeeting(store, data) {
  createRemote(store, ENTITIES.MEETING, data);
}

export function removeMeeting(store, data) {
  removeRemote(store, ENTITIES.MEETING, data);
}

export function updateMeeting(store, data) {
  updateRemote(store, ENTITIES.MEETING, data);
}
