import React from 'react';
import PropTypes from 'prop-types';

import styles from './topPanelIcon.module.scss';

const TopPanelIcon = ({ icon, title, children }) => (
  <div className={styles.topPanelIcon}>
    <div className={styles.icon}>{icon}</div>
    <div>
      <div className={styles.title}>{title}</div>
      <div className={styles.value}>{children}</div>
    </div>
  </div>
);

TopPanelIcon.propTypes = {
  icon: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
};

export default TopPanelIcon;
