const STRUCT = {
  CREATED_AT: 'created_at',
  CUSTOMER_ID: 'customer_id',
  END: 'end',
  GAME_EXERCISE_ID: 'game_exercise_id',
  ID: 'id',
  MEETING_ID: 'meeting_id',
  START: 'start',
  UPDATED_AT: 'updated_at',
};

export default STRUCT;
