const STRUCT = {
  ID: 'id',
  TITLE: 'title',
};

export const ROLES = {
  ADMIN: 1,
  AGENT: 2,
  CLIENT: 3,
};

export default STRUCT;
