import React, { /*useMemo,*/ useState, useCallback } from 'react';
import Timer from '../../../components/timer';
import { MEETING_TIME } from '../../../store/struct/entities/meeting';
import Dialog from '../../../components/dialog';
import Grades from './grades';

import styles from './index.module.scss';
import { branch } from 'baobab-react/higher-order';
import { meSelector } from '../../../store/struct/selectors';

const Step = ({ step, current, children }) => <div className={step === current ? '' : styles.hidden}>{children}</div>;

const MeetingDialog = ({ onEnd }) => {
  const [gradesState, setGradesState] = useState(null);

  const handleClick = useCallback(
    _id => {
      onEnd(gradesState);
    },
    [onEnd, gradesState],
  );

  return (
    <Dialog
      title={
        <>
          <div>Встреча</div>
          <Timer time={MEETING_TIME} />
        </>
      }
      buttons={[
        {
          id: 'save',
          title: 'Сохранить',
        },
      ]}
      onClick={handleClick}
    >
      <Step step={0} current={0}>
        <Grades onChange={setGradesState} />
      </Step>
    </Dialog>
  );
};

export default branch(
  {
    currentUser: meSelector(),
  },
  MeetingDialog,
);
