import React from 'react';
import { branch } from 'baobab-react/higher-order';

import { agentQueuesSelector } from '../../../store/struct/selectors';
import AGENT_QUEUE_STRUCT from '../../../store/struct/calculated/agentQueue';

import Table, { TYPES as TABLE_TYPES } from '../../../components/table';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

const HEADERS = ['Учреждение', 'Должность', 'ФИО', 'Очередь'];

const Meetings = ({ agentQueues }) => {
  const tableConfig = [
    {
      type: TABLE_TYPES.TEXT,
      getValue: entry => {
        return entry[AGENT_QUEUE_STRUCT.INSTITUTION_NAME];
      },
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: entry => {
        return entry[AGENT_QUEUE_STRUCT.POSITION_NAME];
      },
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: entry => {
        return entry[AGENT_QUEUE_STRUCT.NAME];
      },
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: entry => entry[AGENT_QUEUE_STRUCT.QUEUE_LENGTH],
    },
  ];

  return (
    <Table
      className={styles.tableWithHeader}
      keyName={AGENT_QUEUE_STRUCT.CLIENT_ID}
      headers={HEADERS}
      data={agentQueues}
      config={tableConfig}
    />
  );
};

Meetings.propTypes = {
  currentUser: PropTypes.array.isRequired,
};
Meetings.defaultProps = {
  currentUser: [],
};

export default branch(
  {
    agentQueues: agentQueuesSelector(),
  },
  Meetings,
);
