import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.scss';

const NumberText = props => {
  const { onChange } = props;
  const handleChange = useCallback(
    e => {
      onChange(e.target.value);
    },
    [onChange],
  );

  return (
    <input
      className={`${styles.input} ${props.className}`}
      type='number'
      defaultValue={props.value}
      onChange={handleChange}
      disabled={props.disabled}
    />
  );
};

export default NumberText;

NumberText.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

NumberText.defaultProps = {
  onChange: () => null,
  className: '',
  disabled: false,
};
