import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import TabButton from './tab-button/tabButton.jsx';
import TabPanel from './tab-panel/tabPanel.jsx';
import SwitchButton from './switch-button/switchButton';
import Logo from './logo/logo';
import UserInfo from './user-info/userInfo';
import { ICONS } from '../../components/Icons';

import { branch } from 'baobab-react/higher-order';
import { logout } from '../../store/struct/app/actions';

import styles from './index.module.scss';

const MIN_WINDOW_WIDTH = 1200;

const Tabs = props => {
  const { tabs, header, dispatch, ...restProps } = props;

  const [currentTab, setCurrentTab] = useState(0);
  const [shortMode, setShortMode] = useState(window.innerWidth < MIN_WINDOW_WIDTH);

  const logOut = useCallback(() => {
    dispatch(logout);
  }, [dispatch]);

  return (
    <div className={`${styles.tabs} ${shortMode ? styles.shortMode : ''}`}>
      <aside className={styles.tabButtons}>
        <Logo className={styles.logo} shortMode={shortMode} />
        <UserInfo shortMode={shortMode} />
        {tabs.map(({ title, icon }, index) => (
          <TabButton
            isActive={index === currentTab}
            key={`tabPanel_${index}`}
            onClick={() => {
              setCurrentTab(index);
            }}
            icon={icon}
            shortMode={shortMode}
          >
            {title}
          </TabButton>
        ))}
        <TabButton onClick={logOut} icon={ICONS.LogOutIcon} shortMode={shortMode} className={styles.logoutBtn}>
          Выход
        </TabButton>
        <div className={styles.switchButtonPanel}>
          <SwitchButton enable={shortMode} onToggle={setShortMode}>
            Свернуть
          </SwitchButton>
        </div>
      </aside>
      <div className={styles.tabPanels}>
        <div className={styles.header}>{header}</div>
        {tabs.map(({ content: Content, title }, index) => (
          <TabPanel key={`tabPanel_${index}`} title={title} isActive={index === currentTab}>
            <Content {...restProps} />
          </TabPanel>
        ))}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.array,
  header: PropTypes.any,
};

Tabs.defaultProps = {
  tabs: [],
  header: null,
};

export default branch({}, Tabs);
