import React, { useCallback, useEffect, useRef, useState } from 'react';

import setFormFieldInterface from '../setFieldInterface';

import styles from './index.module.scss';

export const PARAMS = {
  ID: 'id',
  TITLE: 'title',
  DISABLED: 'disabled',
};

const selectablePlaceholderValue = 'placeholder-reset';

const Select = ({
  options,
  attrs,
  value,
  onChange,
  onReset,
  className,
  placeholder,
  selectablePlaceholder,
  disabled,
  resetAfterSelection,
}) => {
  const [selectValue, setSelectValue] = useState(
    placeholder ? (selectablePlaceholder ? selectablePlaceholderValue : placeholder) : undefined,
  );
  let timerRef = useRef();

  const handleChange = useCallback(
    e => {
      setSelectValue(e.target.value);

      if (resetAfterSelection) {
        timerRef.current = setTimeout(() => {
          setSelectValue(selectablePlaceholder ? selectablePlaceholderValue : placeholder);
        }, 500);
      }

      if (e.target.value === selectablePlaceholderValue) {
        onReset();
        return;
      }
      onChange(isNaN(e.target.value) ? e.target.value : Number(e.target.value));
    },
    [onChange, onReset, placeholder, resetAfterSelection, selectablePlaceholder],
  );

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  return (
    <select
      className={`${styles.select} ${className}`}
      onChange={handleChange}
      {...attrs}
      disabled={disabled}
      value={value || selectValue}
    >
      {placeholder && (
        <option
          value={selectablePlaceholder ? selectablePlaceholderValue : placeholder}
          key='empty'
          disabled={!selectablePlaceholder}
          hidden={!selectablePlaceholder}
        >
          {placeholder}
        </option>
      )}
      {options.map(opt => (
        <option key={opt[PARAMS.ID]} value={opt[PARAMS.ID]} disabled={opt[PARAMS.DISABLED]}>
          {opt[PARAMS.TITLE]}
        </option>
      ))}
    </select>
  );
};

export default Select;

setFormFieldInterface(Select);
