import React from 'react';
import PropTypes from 'prop-types';

import styles from './switchButton.module.scss';

export const SwitchButton = props => {
  const { className, enable, onToggle } = props;
  const mainClass = `${className} ${styles.switchBtn} ${enable ? styles.active : ''}`;

  const onClick = () => {
    onToggle(!enable);
  };

  return (
    <div className={mainClass}>
      <div className={styles.btnContainer} onClick={onClick}>
        <div className={styles.btn} />
      </div>
      {!enable && props.children}
    </div>
  );
};

SwitchButton.propTypes = {
  className: PropTypes.string,
  enable: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
};

SwitchButton.defaultProps = {
  className: '',
  enable: false,
};

export default SwitchButton;
