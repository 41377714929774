import CLIENT_STRUCT from '../../../../../store/struct/entities/client';
import RESULT_STRUCT from '../../../../../store/struct/entities/meetingResult';

import { TYPES as TABLE_TYPES } from '../../../../../components/table';

export const HEADERS = ['Результат', 'Значение', 'С коэффициентом'];

export const calcWithCoef = ({ fieldData, client, resultCoef, state }) => {
  const coefficientName = fieldData[FIELD_STRUCT.COEFFICIENT];
  const initialFormValue = state[fieldData[FIELD_STRUCT.CONTRIBUTE_TO]];

  if (!fieldData[FIELD_STRUCT.THIRD_COLUMN_REQUIRED]) {
    return Boolean(Number(initialFormValue));
  }

  let val = 0;

  if (!isNaN(initialFormValue)) {
    val = initialFormValue;
  }
  if (val && coefficientName) {
    val *= client[coefficientName];
  }
  if (val) {
    val *= resultCoef;
  }

  return val;
};

const OPTIONS = [
  { id: 0, title: 'Нет' },
  { id: 1, title: 'Да' },
];

export const FIELD_STRUCT = {
  ID: 'id',
  CONTRIBUTE_TO: 'contributeTo',
  TYPE: 'type',
  HEADER: 'header',
  OPTIONS: 'options',
  PROPS: 'props',
  THIRD_COLUMN_REQUIRED: 'thirdColumnRequired',
  COEFFICIENT: 'coefficient',
  POSTFIX: 'postfix',
};

export const FIELDS = [
  {
    [FIELD_STRUCT.ID]: CLIENT_STRUCT.CHECK_FORM,
    [FIELD_STRUCT.CONTRIBUTE_TO]: RESULT_STRUCT.CHECK_FORM,
    [FIELD_STRUCT.TYPE]: TABLE_TYPES.SELECT,
    [FIELD_STRUCT.HEADER]: 'Формуляр',
    [FIELD_STRUCT.OPTIONS]: OPTIONS,
    [FIELD_STRUCT.PROPS]: {
      placeholder: ' ',
      selectablePlaceholder: false,
    },
  },
  {
    [FIELD_STRUCT.ID]: CLIENT_STRUCT.CREATE_REQUEST,
    [FIELD_STRUCT.CONTRIBUTE_TO]: RESULT_STRUCT.CREATE_REQUEST,
    [FIELD_STRUCT.THIRD_COLUMN_REQUIRED]: true,
    [FIELD_STRUCT.HEADER]: 'Заявка',
    [FIELD_STRUCT.POSTFIX]: 'уп.',
    [FIELD_STRUCT.PROPS]: {
      type: 'number',
    },
  },
  {
    [FIELD_STRUCT.ID]: CLIENT_STRUCT.APPROVE_REQUEST,
    [FIELD_STRUCT.CONTRIBUTE_TO]: RESULT_STRUCT.APPROVE_REQUEST,
    [FIELD_STRUCT.TYPE]: TABLE_TYPES.SELECT,
    [FIELD_STRUCT.HEADER]: 'Одобрение',
    [FIELD_STRUCT.OPTIONS]: OPTIONS,
    [FIELD_STRUCT.PROPS]: {
      placeholder: ' ',
      selectablePlaceholder: false,
    },
  },
  {
    [FIELD_STRUCT.ID]: CLIENT_STRUCT.ADD_PATIENTS,
    [FIELD_STRUCT.CONTRIBUTE_TO]: RESULT_STRUCT.ADD_PATIENTS,
    [FIELD_STRUCT.THIRD_COLUMN_REQUIRED]: true,
    [FIELD_STRUCT.COEFFICIENT]: CLIENT_STRUCT.ADD_PATIENTS_COEF,
    [FIELD_STRUCT.HEADER]: 'Назначение',
    [FIELD_STRUCT.POSTFIX]: 'пац.',
    [FIELD_STRUCT.PROPS]: {
      type: 'number',
    },
  },
  {
    [FIELD_STRUCT.ID]: CLIENT_STRUCT.CONTRACT,
    [FIELD_STRUCT.CONTRIBUTE_TO]: RESULT_STRUCT.CONTRACT,
    [FIELD_STRUCT.THIRD_COLUMN_REQUIRED]: true,
    [FIELD_STRUCT.HEADER]: 'Контракт',
    [FIELD_STRUCT.POSTFIX]: 'уп.',
    [FIELD_STRUCT.PROPS]: {
      type: 'number',
    },
  },
  {
    [FIELD_STRUCT.ID]: CLIENT_STRUCT.PRESCRIPT,
    [FIELD_STRUCT.CONTRIBUTE_TO]: RESULT_STRUCT.PRESCRIPT,
    [FIELD_STRUCT.THIRD_COLUMN_REQUIRED]: true,
    [FIELD_STRUCT.COEFFICIENT]: CLIENT_STRUCT.PRESCRIPT_COEF,
    [FIELD_STRUCT.HEADER]: 'Рецепт',
    [FIELD_STRUCT.POSTFIX]: 'пац.',
    [FIELD_STRUCT.PROPS]: {
      type: 'number',
    },
  },
  {
    [FIELD_STRUCT.ID]: CLIENT_STRUCT.PRESCRIPT_RETAIL,
    [FIELD_STRUCT.CONTRIBUTE_TO]: RESULT_STRUCT.PRESCRIPT_RETAIL,
    [FIELD_STRUCT.THIRD_COLUMN_REQUIRED]: true,
    [FIELD_STRUCT.COEFFICIENT]: CLIENT_STRUCT.PRESCRIPT_RETAIL_COEF,
    [FIELD_STRUCT.HEADER]: 'Рецепт ритейл',
    [FIELD_STRUCT.POSTFIX]: 'пац.',
    [FIELD_STRUCT.PROPS]: {
      type: 'number',
    },
  },
  {
    [FIELD_STRUCT.ID]: CLIENT_STRUCT.PRESCRIPT_DISCOUNT,
    [FIELD_STRUCT.CONTRIBUTE_TO]: RESULT_STRUCT.PRESCRIPT_DISCOUNT,
    [FIELD_STRUCT.THIRD_COLUMN_REQUIRED]: true,
    [FIELD_STRUCT.COEFFICIENT]: CLIENT_STRUCT.PRESCRIPT_DISCOUNT_COEF,
    [FIELD_STRUCT.HEADER]: 'Рецепт льгота',
    [FIELD_STRUCT.POSTFIX]: 'пац.',
    [FIELD_STRUCT.PROPS]: {
      type: 'number',
    },
  },
];
