import React from 'react';

import setFormFieldInterface from '../setFieldInterface';

import styles from './index.module.scss';

export const SUBTYPES = {
  INPUT: 'input',
  TEXTAREA: 'textarea',
};

const formattingText = value => {
  const arrText = value.replace(/[\r\n]+$/gi, '').split(/\r?\n|\r/gm);

  return arrText.map((item, idx) => <p key={idx}>{item.trim()}</p>);
};

const Input = ({
  attrs,
  value,
  onChange,
  className,
  subtype,
  readonly,
  disabled,
}) => {
  const Element = subtype;
  return readonly ? (
    <div className={`${styles.common} ${styles.readonly} ${className}`}>
      {formattingText(value)}
    </div>
  ) : (
    <Element
      value={value}
      disabled={disabled}
      className={`${styles.common} ${styles[subtype]} ${className}`}
      onChange={e => onChange(e.target.value)}
      {...attrs}
    />
  );
};

export default Input;

setFormFieldInterface(Input, {
  value: '',
  subtype: 'input',
});
