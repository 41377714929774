import React from 'react';
import PropTypes from 'prop-types';

import styles from './logo.module.scss';
import ICONS from '../../../components/Icons';

const Logo = ({ shortMode, className }) => (
  <div className={`${className} ${styles.logo}`}>
    <ICONS.Logo />
    {!shortMode && <ICONS.Novartis />}
  </div>
);

Logo.propTypes = {
  className: PropTypes.string,
  shortMode: PropTypes.bool.isRequired,
};

Logo.defaultProps = {
  className: '',
};

export default Logo;
