import React from 'react';
import PropTypes from 'prop-types';
import { MEETING_STATUSES } from '../../../src/store/struct/entities/meeting';
import styles from './index.module.scss';

export const STATUSES = MEETING_STATUSES;

const SYMBOL = '';

const StatusText = ({ text, status }) => {
  let statusClassName = '';
  switch (status) {
    case MEETING_STATUSES.pending:
      statusClassName = styles.pending;
      break;
    case MEETING_STATUSES.inProgress:
      statusClassName = styles.inProgress;
      break;
    case MEETING_STATUSES.done:
      statusClassName = styles.done;
      break;
    default:
      statusClassName = styles.pending;
      break;
  }
  let dotClassName = `${styles.dot} ${statusClassName}`;

  return (
    <div className={styles.statusTextContainer}>
      <span className={dotClassName}> {SYMBOL} </span>
      <span className={styles.text}>{text}</span>
    </div>
  );
};

StatusText.propTypes = {
  text: PropTypes.string,
  status: PropTypes.string,
};

StatusText.defaultProps = {
  status: MEETING_STATUSES.pending,
  text: '',
};

export default StatusText;
