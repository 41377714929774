import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.scss';

const TabPanel = ({ isActive, title, children }) => (
  <section className={`${styles.tabPanel} ${isActive ? '' : styles.hidden}`}>
    <header className={styles.tabTitle}>{title}</header>
    <div className={styles.tabBodyOuter}>
      <div className={styles.tabBodyInner}>{children}</div>
    </div>
  </section>
);

TabPanel.propTypes = {
  isActive: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default TabPanel;
