import React from 'react';

import setFormFieldInterface from '../setFieldInterface';

import styles from './index.module.scss';

const Link = ({ attrs, value, onChange, className, readonly, disabled }) => {
  return readonly ? (
    <a className={styles.link} href={value[0]} {...attrs}>
      {value[1]}
    </a>
  ) : (
    <>
      <input
        value={value[0]}
        disabled={disabled}
        className={`${styles.href} ${className}`}
        onChange={e => onChange(e.target.value, value[1])}
        {...attrs}
      />
      <input
        value={value[1]}
        disabled={disabled}
        className={`${styles.text} ${className}`}
        onChange={e => onChange(value[0], e.target.value)}
        {...attrs}
      />
    </>
  );
};

export default Link;

setFormFieldInterface(Link, {
  value: ['', ''],
});
