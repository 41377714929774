import React from 'react';

import Button from '../../button';
import setFormFieldInterface from '../setFieldInterface';

import styles from './index.module.scss';

export const PARAMS = {
  ID: 'id',
  TITLE: 'title',
  THEME: 'theme',
  DISABLED: 'disabled',
  CLASSNAME: 'className',
};

const Buttons = ({ onClick, options }) => {
  return options.map(option => (
    <Button
      key={option[PARAMS.ID]}
      theme={option[PARAMS.THEME]}
      disabled={option[PARAMS.DISABLED]}
      className={`${styles.button} ${option[PARAMS.CLASSNAME]}`}
      onClick={() => onClick(option[PARAMS.ID])}
    >
      {option[PARAMS.TITLE]}
    </Button>
  ));
};

export default Buttons;

setFormFieldInterface(Buttons);
