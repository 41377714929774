import React, { useCallback } from 'react';
import includes from 'lodash.includes';

import setFormFieldInterface from '../setFieldInterface';

import styles from './index.module.scss';

export const PARAMS = {
  ID: 'id',
  TITLE: 'title',
  THEME: 'theme',
  DISABLED: 'disabled',
  CLASSNAME: 'className',
};

const Checkboxes = props => {
  const { value, onChange, disabled, options } = props;
  const handleChange = useCallback(
    id => {
      onChange(
        options.reduce((acc, opt) => {
          if (
            (includes(value, opt[PARAMS.ID]) && id !== opt[PARAMS.ID]) ||
            (!includes(value, opt[PARAMS.ID]) && id === opt[PARAMS.ID])
          ) {
            acc.push(opt[PARAMS.ID]);
          }

          return acc;
        }, []),
      );
    },
    [value, options, onChange],
  );

  return (
    <div className={`${styles.container} ${disabled ? styles.disabled : ''}`}>
      {options.map(opt => {
        return (
          <label
            key={opt[PARAMS.ID]}
            className={
              disabled || opt[PARAMS.DISABLED] ? styles.disabled : null
            }
          >
            <input
              type='checkbox'
              className={`${styles.checkbox} ${opt[PARAMS.CLASSNAME]}`}
              onChange={() => handleChange(opt[PARAMS.ID])}
              checked={includes(value, opt[PARAMS.ID])}
              disabled={disabled || opt[PARAMS.DISABLED]}
            />
            <span className={styles.box} />
            {opt[PARAMS.TITLE]}
          </label>
        );
      })}
    </div>
  );
};

export default Checkboxes;

setFormFieldInterface(Checkboxes);
