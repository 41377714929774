import {
  pinSelector,
  roleSelector,
  authStatusSelector,
  connectionStatusSelector,
  pinSendingStatusSelector,
  userIdSelector,
} from './selectors';
import { connection } from '../..';
import { resetStore } from '../actions';
import { setCookie } from '../../../utils/cookie';
import { AUTHORIZED } from '.';

export function setConnectionStatus(store, value) {
  store.set(connectionStatusSelector(), value);
}

export function setAuthStatus(store, value) {
  if (!value) {
    resetStore(store);
  }
  setCookie(AUTHORIZED, value, { 'max-age': 86400 });
  store.set(authStatusSelector(), value);
  store.commit();
}

export function sendPin(store, pin) {
  store.set(pinSendingStatusSelector(), true);
  setAuthStatus(store, true);
  connection.setPin(pin);
  //change([STRUCT.PIN], pin);
}

export function setCurrentPin(store, pin) {
  store.set(pinSelector(), pin);
  store.set(pinSendingStatusSelector(), false);
}

export function setCurrentRole(store, role) {
  store.set(roleSelector(), role);
}
export function setCurrentUserId(store, userId) {
  store.set(userIdSelector(), userId);
}

export function logout(store) {
  connection.logout();
  store.set(pinSelector(), '');
  store.set(roleSelector(), 0);
}
